import * as React from "react";
import { ListItemButton } from "gatsby-material-ui-components";
import { ListItemIcon, ListItemText } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import HttpsIcon from "@mui/icons-material/Https";
import BlenderIcon from "@mui/icons-material/Blender";
import TransformIcon from "@mui/icons-material/Transform";
import PhotoSizeSelectSmallIcon from "@mui/icons-material/PhotoSizeSelectSmall";

export const mainListItems = (
  <React.Fragment>
    <ListItemButton to="/">
      <ListItemIcon>
        <HomeIcon />
      </ListItemIcon>
      <ListItemText primary="Home" />
    </ListItemButton>
    <ListItemButton to="/crypto">
      <ListItemIcon>
        <HttpsIcon />
      </ListItemIcon>
      <ListItemText primary="Cryptography" />
    </ListItemButton>
    <ListItemButton to="/hash">
      <ListItemIcon>
        <BlenderIcon />
      </ListItemIcon>
      <ListItemText primary="Hash" />
    </ListItemButton>
    <ListItemButton to="/compression">
      <ListItemIcon>
        <PhotoSizeSelectSmallIcon />
      </ListItemIcon>
      <ListItemText primary="Compression" />
    </ListItemButton>
    <ListItemButton to="/encoding">
      <ListItemIcon>
        <TransformIcon />
      </ListItemIcon>
      <ListItemText primary="Encoding" />
    </ListItemButton>
    {/* <ListItemButton to="/encoding">
      <ListItemIcon>
        <TransformIcon />
      </ListItemIcon>
      <ListItemText primary="Encoding" />
    </ListItemButton>
    <ListItemButton to="/compression">
      <ListItemIcon>
        <PhotoSizeSelectSmallIcon />
      </ListItemIcon>
      <ListItemText primary="Compression" />
    </ListItemButton>
    <ListItemButton to="/keys">
      <ListItemIcon>
        <KeyIcon />
      </ListItemIcon>
      <ListItemText primary="Random Keys" />
    </ListItemButton> */}
  </React.Fragment>
);
