import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems } from './ListItems';


const mdTheme = createTheme();
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export const Head = () => (
  <>
    <title>
      Encryption, Hashing, Encoding and Compression Tools - AES, MD5, SHA256, Base64, GZIP |
      TxtWizard
    </title>
    <meta
      name="description"
      content="provides various encryption tools such as AES/GCM and AES/CBC, hash generating tools like MD5, SHA1, SHA256, SHA3, and encoding options including Base64 and HEX. Try our easy-to-use online tools today!"
    />
    <script
      async
      src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9932778305312246"
      crossorigin="anonymous"
    ></script>
  </>
);

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

export default function Layout({ children }) {
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = () => {
      setOpen(!open);
    };

    return (
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar position="absolute" open={open}>
            <Toolbar
              sx={{
                pr: "24px", // keep right padding when drawer closed
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: "36px",
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                TxtWizard
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={open}>
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                px: [1],
              }}
            >
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">{mainListItems}</List>
          </Drawer>
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />

            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                {/* 메인 콘텐츠 영역 */}
                <Grid item xs={12} md={8} lg={9}>
                  {children}
                </Grid>

                {/* 사이드 광고 영역 */}
                <Grid item xs={12} md={4} lg={3}>
                    <a href="https://www.amazon.com/dp/B0CSG1K9JK" target="_blank" rel="noreferrer">
                  <Card sx={{ maxWidth: 345 }}>
                    <CardMedia
                      component="img"
                      height="400"
                      image="/devmind_bookcover.png" // 이미지 경로를 적절하게 수정하세요.
                      alt="DevMind Book Cover"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h7" component="div">
                        Unleash Your Development Potential: Navigating the Art
                        and Science of Software Mastery
                      </Typography>
                      {/* <Typography variant="body2" color="text.secondary">
                        Dive into 'DevMinds' and embark on a transformative
                        journey through the intricate landscape of software
                        development. This guide is not just a book; it's a
                        mentor that challenges you to grow, innovate, and
                        contribute to the digital realm in meaningful ways.
                        Explore the strategic, psychological, and philosophical
                        facets of being a developer in the dynamic world of
                        technology. From personal growth to technical
                        leadership, problem-solving to collaborative innovation,
                        'DevMinds' offers invaluable insights for every stage of
                        your developer journey. Whether you're starting your
                        path, seeking to elevate your skills, or aiming to lead
                        with vision, this book provides the framework to
                        navigate the challenges, embrace continuous learning,
                        and excel in the ever-evolving industry. Unlock the
                        secrets to crafting civilizations in code and shaping a
                        better digital society with 'DevMinds'.
                      </Typography> */}
                    </CardContent>
                  </Card>
                  </a>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    );
}